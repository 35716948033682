<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.communication.title") }}</h3>
    <communication-filters></communication-filters>

    <template v-if="$is1Board">
      <b-button
        class="float-right mb-3"
        size="md"
        variant="secondary"
        @click="openCreateModal"
      >
        {{ $t("page.communication.newCommunication") }}
      </b-button>
      <communication-user-filters
        :state="createModalState"
        :groups="groups"
        @close="closeCreateModal"
        @proceed="startCommunicationCreation"
      />
    </template>

    <template v-else>
      <b-button
        class="float-right mb-3"
        size="md"
        variant="secondary"
        v-b-modal.select-condominium-modal
      >
        {{ $t("page.communication.newCommunication") }}
      </b-button>
      <select-condominium :condominiums="condominiums"></select-condominium>
    </template>

    <infinite-scroll @load="fetchData">
      <communication-table
        :communications="communications"
        :fields="fields"
        :sortBy.sync="sortBy"
        :sortDesc.sync="sortDesc"
        :retrieveDataCallback="retrieveCommunicationsCallback"
        no-local-sorting
      />
    </infinite-scroll>
  </div>
</template>

<script>
import { EventBus } from "./../eventBus.js";
import { appMixin } from "./../mixins";

import SelectCondominium from "../components/Communication/SelectCondominium";
import CommunicationTable from "../components/Condominium/CommunicationTable";
import CommunicationFilters from "../components/Communication/CommunicationFilters";
import CommunicationUserFilters from "../components/Communication/Groups/UserFilters";
import InfiniteScroll from "./../components/Base/BaseInfiniteScroll";

export default {
  components: {
    SelectCondominium,
    CommunicationTable,
    CommunicationFilters,
    CommunicationUserFilters,
    InfiniteScroll
  },
  mixins: [appMixin],
  data() {
    return {
      dataLoaded: false,
      currentCommunication: null,
      preview: false,
      condominiums: [],
      createModalState: false,
      activeGroupsRetrieved: false
    };
  },
  mounted() {
    EventBus.$on("eventBusReloadCommunications", () => this.fetchData(true));
    this.fetchData(true);
  },
  computed: {
    communications() {
      return this.$store.getters["communication/getUserCommunications"] || [];
    },
    fields() {
      const initFields = [
        { key: "subject", label: "subject", sort: true },
        { key: "createdAt", label: "date", sort: true },
        { key: "body", label: "description", sort: true },
        { key: "readcounter", label: "readcounter", sort: false },
        { key: "documents", label: "documents" },
        { key: "attachments", label: "attachments" },
        // { key: "download", label: "download" },
        { key: "status", label: "status" },
        { key: "details", label: "" }
      ];
      if (!this.$is1Board)
        initFields.splice(6, 0, { key: "download", label: "download" });
      return initFields;
    },
    groups() {
      return this.$store.getters["communication/getActiveGroups"];
    },
    sortBy: {
      get() {
        const sortBy = this.$store.getters["communication/getSortBy"];
        return this.fields.find(f => f.sortBy === sortBy)?.key || sortBy;
      },
      set(value) {
        const sortBy = this.fields.find(f => f.key === value)?.sortBy || value;
        console.log(sortBy);
        this.$store.commit("communication/SET_SORT_BY", sortBy);
        this.fetchData(true);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["communication/getSortDesc"];
      },
      set(value) {
        this.$store.commit("communication/SET_SORT_DESC", value);
        this.fetchData(true);
      }
    }
  },
  methods: {
    async openCreateModal() {
      if (this.activeGroupsRetrieved) this.createModalState = true;
      else
        this.retrieveActiveGroups(() => {
          this.createModalState = true;
        });
    },
    closeCreateModal() {
      this.createModalState = false;
    },
    async retrieveActiveGroups(successCallbackFn) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("communication/retrieveActiveGroups");
        this.activeGroupsRetrieved = true;
        if (successCallbackFn) successCallbackFn();
      } catch (error) {
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async fetchData(initial) {
      try {
        // this.$store.commit("loader/SET_LOADER", {});
        this.dataLoaded = false;
        if (initial) {
          await this.$store.dispatch("condominium/retrieveCondominiums");
          this.condominiums = await this.$store.getters[
            "condominium/getActiveCondominiums"
          ];
        }
        await this.$store.dispatch("communication/retrieveUserCommunications", {
          initial,
          hasPagination: true
        });
        this.dataLoaded = true;
      } catch (error) {
        console.log(error);
      } finally {
        // this.$store.commit("loader/RESET");
      }
    },
    retrieveCommunicationsCallback() {
      return this.$store.dispatch("communication/retrieveUserCommunications");
    },
    async startCommunicationCreation(filters) {
      this.$store.commit("communication/RESET");
      this.$store.commit("communication/SET_GROUP_USER_FILTERS", filters);
      this.$router.push({ name: "NewGroupCommunication" });
    }
  },
  beforeDestroy() {
    EventBus.$off("eventBusReloadCommunications");
  }
};
</script>

<style lang="scss" scoped></style>
